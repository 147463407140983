import React, { useEffect, useState } from 'react'
import './Main.css'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import { AddRegisterCustomer, ValidateToken } from '../../helpers/PostRquest/postRquest';

const Main = () => {
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState(false)
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: "",
            mobileNo: "",
            email: "",
            incorporateID: ""
        }
    })

    useEffect(() => {


        var url = new URL(window.location.href);

        // ValidateToken(url.searchParams.get("token"))
        //     .then((data) => {
        //         console.log(data);
        //         if (data) {
        //             localStorage.getItem('ValidToken', data.message)
        //         }
        //         else {
        //             navigate('/notValidUser')
        //         }
        //     })
        //     .catch((error) => console.log(error));

    }, [])

    const onSubmit = (data) => {
        setIsLoader(true)
        console.log('Form submit', data)
        // console.log('------------>',`${process.env.API_URL}`);

        AddRegisterCustomer(
            {
                "name": data.name,
                "mobileNo": data.mobileNo,
                "email": data.email,
                "incorporateID": parseInt(data.incorporateID)
            }
        )
            .then((data) => {
                console.log(data);
                if (data) {
                    setIsLoader(false)
                    localStorage.setItem('FormData', JSON.stringify(data))

                    if (data.incorporateID == '1') {
                        navigate('/public')
                    }
                    else if (data.incorporateID == '2') {
                        navigate('/private')
                    }
                    else if (data.incorporateID == '3') {
                        navigate('/llp')
                    }
                    else if (data.incorporateID == '4') {
                        navigate('/opc')
                    }
                    else {
                        navigate('/others')
                    }
                }
            })
            .catch((error) => console.log(error));

    }

    return (
        <div className="container">
            <div className="row align-items-stretch no-gutters contact-wrap">
                <div className="col-md-12">
                    <div className="form h-100">
                        <h5 class="card-title" style={{
                            textAlign: 'center',
                            margin: '10px',
                            fontSize: '26px'
                        }}>Get Started</h5>
                        {isLoader ? (
                            <div className='container'>
                                <div className=" loader"></div>
                            </div>
                        ) : (
                            <form className="mb-5" id="contactForm" name="contactForm" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6 form-group mb-3">
                                        <label for="" className="col-form-label">Name *</label>
                                        <input type="text" className="form-control" name="name" id="name"
                                            placeholder="Your name" {...register("name", {
                                                required: true,
                                            })} />
                                        {errors.name && (<small id="" className="form-text text-muted">Please enter name</small>)}

                                    </div>
                                    <div className="col-md-6 form-group-mb-3">
                                        <label for="" className="col-form-label">Mobile *</label>
                                        <input type="number" min="0" className="form-control" name="Mobile" id="Mobile"
                                            placeholder="Your Mobile"
                                            {...register("mobileNo", {
                                                required: true,
                                                validate: {
                                                    isValidNumber: value => {
                                                        const phoneRegex = /^[0-9]{10}$/; // Regex for 10-digit mobile number
                                                        return phoneRegex.test(value) || "Mobile Number must be a 10-digit number";
                                                    }
                                                }
                                            })} />
                                        {errors.mobileNo && (<small id="" className="form-text text-muted"> {errors.mobileNo.message}</small>)}

                                    </div>
                                    <div className="col-md-6 form-group mb-3">
                                        <label for="" className="col-form-label">Email *</label>
                                        <input type="text" className="form-control" name="email" id="email"
                                            placeholder="Your email"   {...register("email", {
                                                required: true,
                                            })} />
                                        {errors.email && (<small id="" className="form-text text-muted">Please enter email</small>)}

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 form-group mb-3">
                                        <label for="budget" className="col-form-label">Type of entity you want to incorporate
                                            option *</label>
                                        <select className="form-control" id="budget" name="budget"
                                            {...register("incorporateID", {
                                                required: true,
                                            })}>
                                            <option selected value="">--Select--</option>
                                            <option value="1">Public Limited </option>
                                            <option value="2">Private Limited</option>
                                            <option value="3">Limited Liability Partnership</option>
                                            <option value="4">One Person Company</option>
                                            <option value="5">Others Company</option>
                                        </select>
                                        {errors.incorporateID && (<small id="" className="form-text text-muted">Please select atleast one</small>)}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <span className="submitting"></span>
                                    </div>
                                </div>
                            </form>
                        )}

                        <div id="form-message-warning mt-4"></div>
                        {/* <div id="form-message-success">
                            Your message was sent, thank you!
                        </div> */}

                    </div>
                </div>
            </div>


        </div>
    )
}

export default Main