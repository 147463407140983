import React, { useEffect, useState } from "react";
import { Dropdown, Form, Button, Row, Col } from "react-bootstrap"; // Make sure you have react-bootstrap installed
import uploadFileToBlob, { handlePriview } from "../../helpers/azureBlob";
import { AddRegisterCustomer } from "../../helpers/PostRquest/postRquest";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const DynamicForm = () => {
  const [isLoader, setIsLoader] = useState(false)
  const [numberOfDirectorDropdowns, setNumberOfDirectorDropdowns] =
    useState(1);
  const [validationErrors, setValidationErrors] = useState({});
  useEffect(() => {
    let x = localStorage.getItem("FormData");
    x = JSON.parse(x);
    //  console.log(x.privateCompany.noOfDirectors);
    if (x.entityForm != null) {
      setNumberOfDirectorDropdowns(x.entityForm.noOfDirectors);
    } else if (x.privateCompany != null) {
      setNumberOfDirectorDropdowns(x.privateCompany.noOfDirectors);
    } else if (x.llpCompany != null) {
      setNumberOfDirectorDropdowns(x.llpCompany.noOfDirectors);
    } else if (x.opcCompany != null) {
      setNumberOfDirectorDropdowns(x.opcCompany.noOfDirectors);
    } else if (x.otherCompany != null) {
      setNumberOfDirectorDropdowns(x.otherCompany.noOfDirectors);
    }
  }, []);

  // Initialize state for all forms
  const [directorFormData, setDirectorFormData] = useState(
    Array.from({ length: numberOfDirectorDropdowns }, () => ({
      name: "",
      fatherName: "",
      dateOfBirth: "",
      nationality: "",
      placeOfBirth: "",
      occupation: "",
      educationalQualification: "",
      copyOfPan: "",
      copyOfAadhar: "",
      passport: "",
      mobileNo: "",
      emailAddress: "",
      currentAddress: "",
      currentAddressAttachment: "",
      proofOfIdentity: "",
      noOfSharesSubscribed: "",
      passportSizePhoto: "",
      entityDetails: [
        // {
        // directorNameOfCompany: "",
        // natureOfDirectorship: "",
        // directorSince: "",
        // shareHolderType: "",
        // noOfShares: "",
        // shareHolderSince: "",
        // },
      ],
      directorIdentificationNo: ""

    }))
  );
const[isSubmited,setIsSubmited]=useState(false)
  // Handle input change
  const handleDirectorInputChange = (index, event) => {

    const { name, value } = event.target;
    const newFormData = [...directorFormData];

    if (name.startsWith("entityDetail.")) {
      const detailIndex = parseInt(name.split(".")[1]);
      const fieldName = name.split(".")[2];

      // Ensure the entityDetails array is initialized
      if (!newFormData[index].entityDetails[detailIndex]) {
        newFormData[index].entityDetails[detailIndex] = {};
      }

      newFormData[index].entityDetails[detailIndex][fieldName] = value;
    } else {
      // Handle other director fields as previously defined
      if (!newFormData[index]) {
        newFormData[index] = {};
      }
      newFormData[index][name] = value;
    }

    if(isSubmited){
      const errors = validateDirectorFormData(directorFormData);
      setValidationErrors(errors);

    }

    setDirectorFormData(newFormData);
  };

  const handleAddEntityDetail = (index) => {
    const newFormData = [...directorFormData];
    // Check if directorPartners exists, if not, initialize it
    if (!newFormData[index].entityDetails) {
      newFormData[index].entityDetails = [];
    }
    newFormData[index].entityDetails.push({
      directorNameOfCompany: "",
      natureOfDirectorship: "",
      directorSince: "",
      shareHolderType: "",
      noOfShares: "",
      shareHolderSince: "",
    });
    setDirectorFormData(newFormData);
  };

  const handleDeleteEntityDetail = (directorIndex, entityIndex) => {
    const newFormData = [...directorFormData];
    newFormData[directorIndex].entityDetails.splice(entityIndex, 1);
    setDirectorFormData(newFormData);
  };

  useEffect(() => {
    setDirectorFormData((prevData) =>
      Array.from(
        { length: numberOfDirectorDropdowns },
        (_, i) => prevData[i] || {}
      )
    );
  }, [numberOfDirectorDropdowns]);

  const navigate = useNavigate();
  // Handle submit
  const handleSubmit = (event) => {
    setIsSubmited(true)
    event.preventDefault();
    // setIsLoader(true)
    // console.log("Form Data:", directorFormData);
    const errors = validateDirectorFormData(directorFormData);
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      // Handle errors (e.g., display them in the UI)
      console.log("Validation errors:", errors);
      return;
    }

    let x = localStorage.getItem("FormData");
    x = JSON.parse(x);

    x.directors = directorFormData;
    AddRegisterCustomer(x)
      .then((data) => {
        // console.log(data);
        // setIsLoader(false)
        if (data) {
          localStorage.setItem("FormData", JSON.stringify(data));

          if (data.shareholder != "0") {
            navigate("/shareholder");
          } else {
            alert("no shareholder selected");
          }
        }
        else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!"
          });
        }
      })
      .catch((error) => console.log(error));
  };

  // const handleDirectorPanCopyInputDocChange =async (index, e)=>{
  //     if(!(e.target.files[0])){
  //         return
  //     }

  //     // *** UPLOAD TO AZURE STORAGE ***
  //     const blobUrl = await uploadFileToBlob(e.target.files[0]).then((res) => {
  //         handleDirectorInputChange(index,`${handlePriview(e.target.files[0].name)}`)

  //     });
  // }

  // Create an array of dropdowns with forms inside
  const directorDropdowns = Array.from(
    { length: numberOfDirectorDropdowns },
    (_, index) => (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {isLoader ? (
          <div className='container'>
            <div className=" loader"></div>
          </div>
        ) : (
          <Dropdown key={index} className="mb-3 mt-5">
            <Button
              className="btn btn-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#dropdown-basic-${index}`}
              aria-expanded="false"
              aria-controls={`dropdown-basic-${index}`}
            >
              Director Details {index + 1}
            </Button>

            <div className="collapse" id={`dropdown-basic-${index}`}>
              <div
                className="card card-body"
                style={{ width: "700px", alignItems: "center" }}
              >
                <Form style={{ width: "90%", alignContent: "center" }}>
                  <Form.Group controlId={`form${index}`}>
                    {/* <Form.Label>Form {index + 1} Input</Form.Label> */}
                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            // value={directorFormData[index].name}
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.name}
                          />

                          {validationErrors[index]?.name && (
                            <div className="text-danger">{validationErrors[index].name}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Father Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="fatherName"
                            placeholder="Enter Father Name"
                            // value={directorFormData[index].FatherName}
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.fatherName}
                          />

                          {validationErrors[index]?.fatherName && (
                            <div className="text-danger">{validationErrors[index].fatherName}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>



                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Date of Birth</Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Enter Date of Birth"
                            name="dateOfBirth"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.dateOfBirth}
                          />
                          {validationErrors[index]?.dateOfBirth && (
                            <div className="text-danger">{validationErrors[index].dateOfBirth}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Nationality</Form.Label>
                          <Form.Control
                            type="text"
                            name="nationality"
                            placeholder="Enter Nationality"
                            // value={directorFormData[index].Nationality}
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.nationality}
                          />
                          {validationErrors[index]?.nationality && (
                            <div className="text-danger">{validationErrors[index].nationality}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>


                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Place Of Birth (District and State)</Form.Label>
                          <Form.Control
                            type="text"
                            name="placeOfBirth"
                            placeholder="Enter Place Of Birth"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.placeOfBirth}
                          />
                          {validationErrors[index]?.placeOfBirth && (
                            <div className="text-danger">{validationErrors[index].placeOfBirth}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Occupation</Form.Label>
                          <Form.Control
                            type="text"
                            name="occupation"
                            placeholder="Enter Occupation"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.occupation}
                          />
                          {validationErrors[index]?.occupation && (
                            <div className="text-danger">{validationErrors[index].occupation}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Educational Qualification</Form.Label>
                          <Form.Control
                            type="text" placeholder="Enter Educational Qualification"
                            name="educationalQualification"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.educationalQualification}
                          />
                          {validationErrors[index]?.educationalQualification && (
                            <div className="text-danger">{validationErrors[index].educationalQualification}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Copy of PAN Self Attested</Form.Label>
                          <Form.Control
                            type="file" accept="application/pdf"
                            name="copyOfPan"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.copyOfPan}
                          />
                          {validationErrors[index]?.copyOfPan && (
                            <div className="text-danger">{validationErrors[index].copyOfPan}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Add other fields similarly */}

                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Copy of Aadhaar Self Attested</Form.Label>
                          <Form.Control
                            type="file" accept="application/pdf"
                            name="copyOfAadhar"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.copyOfAadhar}
                          />
                          {validationErrors[index]?.copyOfAadhar && (
                            <div className="text-danger">{validationErrors[index].copyOfAadhar}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Passport (If Foreign National)</Form.Label>
                          <Form.Control
                            type="file" accept="application/pdf"
                            name="passport"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.passport}
                          />
                          {validationErrors[index]?.passport && (
                            <div className="text-danger">{validationErrors[index].passport}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Mobile Number</Form.Label>
                          <Form.Control
                            type="number" placeholder="Enter Mobile Number"
                            name="mobileNo"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.mobileNo}
                          />
                          {validationErrors[index]?.mobileNo && (
                            <div className="text-danger">{validationErrors[index].mobileNo}</div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="emailAddress"
                            placeholder="Enter Email"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.emailAddress}
                          />
                          {validationErrors[index]?.emailAddress && (
                            <div className="text-danger">{validationErrors[index].emailAddress}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-3">


                      <Form.Group>
                        <label className="form-label" for="NameRelevanceTextArea">
                          Current Address (eg. Proof/Electricity Bill/Mobile Bill
                          /Tax Receipt/Bank Statement not older then 2 months)
                        </label>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Control
                              type="text" placeholder='Enter Current Address'
                              name="currentAddress"
                              onChange={(event) =>
                                handleDirectorInputChange(index, event)
                              }
                              className="form-control"
                              // id="file-input"
                              // for="file-input"
                              isInvalid={!!validationErrors[index]?.currentAddress}
                            />
                            {validationErrors[index]?.currentAddress && (
                              <div className="text-danger">{validationErrors[index].currentAddress}</div>
                            )}

                          </div>

                          <div className="col-md-6">

                            <Form.Control
                              type="file" accept="application/pdf"
                              name="currentAddressAttachment"
                              placeholder="Current Address Attachment"
                              className="form-control col-md-6"
                              id="file-input"
                              for="file-input"
                              onChange={(event) =>
                                handleDirectorInputChange(index, event)
                              }
                              isInvalid={!!validationErrors[index]?.currentAddressAttachment}
                            />
                            {validationErrors[index]?.currentAddressAttachment && (
                              <div className="text-danger">{validationErrors[index].currentAddressAttachment}</div>
                            )}
                          </div>
                        </div>
                      </Form.Group>

                    </Row>

                    <Row className="mb-3">
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Proof Of Identity (Any One: Voter ID/ Passport/
                            Driving Licence)
                          </Form.Label>
                          <Form.Control
                            type="file" accept="application/pdf" placeholder="Enter Proof Of Identity"
                            name="proofOfIdentity"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.proofOfIdentity}
                          />
                          {validationErrors[index]?.proofOfIdentity && (
                            <div className="text-danger">{validationErrors[index].proofOfIdentity}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>No. Of Shares Subscribed </Form.Label>
                          <Form.Control
                            type="number" min="0"
                            placeholder="Enter No. Of Shares Subscribed"
                            name="noOfSharesSubscribed"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.noOfSharesSubscribed}
                          />
                          {validationErrors[index]?.noOfSharesSubscribed && (
                            <div className="text-danger">{validationErrors[index].noOfSharesSubscribed}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>


                    <Form.Group className="mb-3">
                      <Form.Label>
                        Number of entities in which the director has an interest
                        - Mention the number of companies they are directors &
                        shareholders in
                      </Form.Label>
                      {directorFormData[index]?.entityDetails?.map(
                        (entity, entityIndex) => (
                          <div key={entityIndex} className="mb-1 ">
                            <div class="row d-flex flex-row gap-3 mb-3">
                              <Form.Control
                                className="col-sm"
                                type="text"
                                name={`entityDetail.${entityIndex}.directorNameOfCompany`}
                                placeholder="Name of Company"
                                value={entity.directorNameOfCompany}
                                onChange={(event) =>
                                  handleDirectorInputChange(index, event)
                                }
                              />
                              <Form.Control
                                className="col-sm"
                                type="text"
                                name={`entityDetail.${entityIndex}.natureOfDirectorship`}
                                placeholder="Nature of Directorship"
                                value={entity.natureOfDirectorship}
                                onChange={(event) =>
                                  handleDirectorInputChange(index, event)
                                }
                              />
                              <Form.Control
                                className="col-sm"
                                type="date"
                                name={`entityDetail.${entityIndex}.directorSince`}
                                placeholder="Director Since"
                                value={entity.directorSince}
                                onChange={(event) =>
                                  handleDirectorInputChange(index, event)
                                }
                              />
                            </div>
                            <div class="row d-flex flex-row gap-3">
                              <Form.Control
                                className="col-sm"
                                type="text"
                                name={`entityDetail.${entityIndex}.shareHolderType`}
                                placeholder="Shareholder Type"
                                value={entity.shareHolderType}
                                onChange={(event) =>
                                  handleDirectorInputChange(index, event)
                                }
                              />
                              <Form.Control
                                className="col-sm"
                                type="number"
                                name={`entityDetail.${entityIndex}.noOfShares`}
                                placeholder="No. of Shares"
                                value={entity.noOfShares}
                                onChange={(event) =>
                                  handleDirectorInputChange(index, event)
                                }
                              />
                              <Form.Control
                                className="col-sm"
                                type="date"
                                name={`entityDetail.${entityIndex}.shareHolderSince`}
                                placeholder="Shareholder Since"
                                value={entity.shareHolderSince}
                                onChange={(event) =>
                                  handleDirectorInputChange(index, event)
                                }
                              />
                            </div>
                            <div style={{ textAlign: 'right' }}>

                              <Button
                                style={{ textAlign: 'right' }}
                                variant="danger mt-1 "
                                onClick={() =>
                                  handleDeleteEntityDetail(index, entityIndex)
                                }
                              >
                                -
                              </Button>
                            </div>
                          </div>
                        )
                      )}
                      <Button
                        // className="btn btn-primary btn-sm"
                        variant="success mt-1 "
                        onClick={() => handleAddEntityDetail(index)}
                      >
                        +
                      </Button>
                    </Form.Group>

                    <Row className="mb-3">
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Passport Size Photo (White Background)
                          </Form.Label>
                          <Form.Control
                            type="file" accept="application/pdf"
                            name="passportSizePhoto"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.passportSizePhoto}
                          />
                          {validationErrors[index]?.passportSizePhoto && (
                            <div className="text-danger">{validationErrors[index].passportSizePhoto}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Director Identification Number</Form.Label>
                          <Form.Control
                            type="number" min="0" placeholder="Enter Director Identification Number"
                            name="directorIdentificationNo"
                            onChange={(event) =>
                              handleDirectorInputChange(index, event)
                            }
                            isInvalid={!!validationErrors[index]?.directorIdentificationNo}
                          />
                          {validationErrors[index]?.directorIdentificationNo && (
                            <div className="text-danger">{validationErrors[index].directorIdentificationNo}</div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Form.Group className="mb-3">
                                        <Form.Label>Permanent Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="permanentAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Present Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="presentAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Stay Duration Present Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="stayDurationPresentAddress"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Present Address If Less Than Year</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="presentAddressIfLessThanYear"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                 
                                    <Form.Group className="mb-3">
                                        <Form.Label>Residential Proof</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="residentialProof"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                  
                                    <Form.Group className="mb-3">
                                        <Form.Label>Director Entity Count</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="directorEntityCount"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Director Identification No</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="directorIdentificationNo"
                                            onChange={(event) => handleDirectorInputChange(index, event)}
                                        />
                                    </Form.Group>
                                     */}


                    {/* Add other fields similarly */}
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Dropdown>
        )}
      </div>
    )
  );


  // Validation of Form


  const validateDirectorFormData = (data) => {
    const errors = {};

    data.forEach((director, index) => {
      const directorErrors = {};

      // Ensure director object exists before accessing its properties
      if (director) {
        if (!director.name) {
          directorErrors.name = "Name is required";
        }

        if (!director.fatherName) {
          directorErrors.fatherName = "Father Name is required";
        }

        // Check if emailAddress exists and is valid
        if (!director.emailAddress || !director.emailAddress.includes('@')) {
          directorErrors.emailAddress = "Email is invalid";
        }
        if (!director.currentAddress) {
          directorErrors.currentAddress = "Current Address is required";
        }

        // Check mobile number length
        // console.log(director?.mobileNo?.length);

        if (!director.mobileNo || director?.mobileNo?.length != 10) {
          directorErrors.mobileNo = "Mobile number must be 10 digits";
        }

        if (!director.dateOfBirth) {
          directorErrors.dateOfBirth = "Date of Birth is required";
        } else {
          const today = new Date();
          const dob = new Date(director.dateOfBirth);
          if (dob >= today) {
            directorErrors.dateOfBirth = "Date of Birth must be before today";
          }
        }

        if (!director.nationality) {
          directorErrors.nationality = "Nationality is required";
        }

        if (!director.placeOfBirth) {
          directorErrors.placeOfBirth = "Place of Birth is required";
        }

        if (!director.occupation) {
          directorErrors.occupation = "Occupation is required";
        }

        if (!director.educationalQualification) {
          directorErrors.educationalQualification = "Educational Qualification is required";
        }



        // if (!director.currentAddress) {
        //   directorErrors.currentAddress = "Current Address is required";
        // }

        if (!director.currentAddressAttachment) {
          directorErrors.currentAddressAttachment = "Current Address Attachment is required";
        }

        if (!director.proofOfIdentity) {
          directorErrors.proofOfIdentity = "Proof of Identity is required";
        }

        if (!director.noOfSharesSubscribed) {
          directorErrors.noOfSharesSubscribed = "Number of Shares Subscribed is required";
        }

        if (!director.copyOfPan) {
          directorErrors.copyOfPan = "Copy of PAN is required";
        }
        if (!director.copyOfAadhar) {
          directorErrors.copyOfAadhar = "Copy of Aadhaar is required";
        }
        if (!director.passport) {
          directorErrors.passport = "Passport is required";
        }
        if (!director.passportSizePhoto) {
          directorErrors.passportSizePhoto = "Passport Size Photo is required";
        }
        if (!director.directorIdentificationNo) {
          directorErrors.directorIdentificationNo = "Director Identification Number is required";
        }

        // Validate entityDetails
        if (director.entityDetails) {
          director.entityDetails.forEach((entity, entityIndex) => {
            const entityErrors = {};

            if (!entity.directorNameOfCompany) {
              entityErrors.directorNameOfCompany = "Company name is required";
            }

            // Add other entity validations here

            if (Object.keys(entityErrors).length > 0) {
              directorErrors[`entityDetail.${entityIndex}`] = entityErrors;
            }
          });
        }

        if (Object.keys(directorErrors).length > 0) {
          errors[index] = directorErrors;
        }
      } else {
        errors[index] = { general: "Director data is missing." };
      }
    });

    return errors;
  };


  return (
    <div className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form
        className="form-group col-md-12"
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "200px",
        }}
      >
        {directorDropdowns}

        <div style={{
          display: 'block',
          width: "230%",
          textAlign: 'end'
        }}>

          <Button
            variant="primary"
            className="form-group"
            type="submit"

          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DynamicForm;
