import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Button, Row, Col } from 'react-bootstrap'; // Make sure you have react-bootstrap installed
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const DynamicForm = () => {
    const navigate = useNavigate();
    const[isSubmited,setIsSubmited]=useState(false)
    const [numberOfPartnerDropdowns, setNumberOfPartnerDropdowns] = useState('1');
    const [validationErrors, setValidationErrors] = useState({});
    useEffect(() => {
        let x = localStorage.getItem("FormData");
        x = JSON.parse(x);
        //  console.log(x.entityForm.noOfDirectors);
        if (x.entityForm != null) {
            setNumberOfPartnerDropdowns(x.entityForm.noOfPartners);
        } else if (x.privateCompany != null) {
            setNumberOfPartnerDropdowns(x.privateCompany.noOfPartners);
        } else if (x.llpCompany != null) {
            setNumberOfPartnerDropdowns(x.llpCompany.noOfPartners);
        } else if (x.opcCompany != null) {
            setNumberOfPartnerDropdowns(x.opcCompany.noOfPartners);
        } else if (x.otherCompany != null) {
            setNumberOfPartnerDropdowns(x.otherCompany.noOfPartners);
        }
    }, []);

    // Initialize state for all forms
    const [partnerFormData, setPartnerFormData] = useState(
        Array.from({ length: numberOfPartnerDropdowns }, () => ({
            partnerPartners: [{}],
            name: "",
            fatherName: "",
            dateOfBirth: "",
            nationality: "",
            placeOfBirth: "",
            occupation: "",
            educationalQualification: "",
            copyOfPan: "",
            copyOfAadhar: "",
            passport: "",
            mobileNo: "",
            emailAddress: "",
            currentAddress: "",
            currentAddressAttachment: "",
            proofOfIdentity: "",
            noOfSharesSubscribed: "",
            profitLossRatio: "",
            passportSizePhoto: "",
            directorIdentificationNo: "",
            step: "final"
        }))
    );

    // Handle input change
    const handlePartnerInputChange = async (index, event, qIndex = null) => {
        const { name, value, type, files } = event.target;
        const newFormData = [...partnerFormData];

        // Ensure that newFormData[index] is initialized
        if (index < 0 || index >= newFormData.length) {
            console.error("Index out of bounds");
            return;
        }

        if (!newFormData[index]) {
            newFormData[index] = {}; // Initialize if undefined
        }


        // console.log({qIndex,name,value});

        if (qIndex != null) {
            newFormData[index].partnerPartners[qIndex][name] = value;
            // console.log({ newFormData });
        }

        if (type === 'file') {

            if (!(event.target.files[0])) {
                return
            }

            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(event.target.files[0]).then((res) => {
                newFormData[index][name] = handlePriview(event.target.files[0].name);
            });
        } else {
            newFormData[index][name] = value;
        }

        if(isSubmited){
        const errors = validateDirectorFormData(partnerFormData);
        setValidationErrors(errors);
        }
        setPartnerFormData(newFormData);
        // console.log(newFormData);

    };
    const addEducationalQualification = (index) => {
        const newFormData = [...partnerFormData];
        // Check if partnerPartners exists, if not, initialize it
        if (!newFormData[index].partnerPartners) {
            newFormData[index].partnerPartners = [];
        }
        // console.log("checking------->", newFormData[index])
        newFormData[index].partnerPartners.push({
            partnerNameOfCompany: "",
            natureOfDirectorship: "",
            partnerSince: "",
            shareHolderType: "",
            noOfShares: "",
            shareHolderSince: ""
        }); // Add new entity
        setPartnerFormData(newFormData);
    };

    const removeEducationalQualification = (index, qualificationIndex) => {
        const newFormData = [...partnerFormData];
        newFormData[index].partnerPartners.splice(qualificationIndex, 1); // Remove specific qualification
        setPartnerFormData(newFormData);
    };


    useEffect(() => {
        setPartnerFormData(prevData =>
            Array.from({ length: numberOfPartnerDropdowns }, (_, i) =>
                prevData[i] || { name: '', fatherName: '', /* other fields */ }
            )
        );
    }, [numberOfPartnerDropdowns]);


    // Handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmited(true)
        const errors = validateDirectorFormData(partnerFormData);
        setValidationErrors(errors);
        if (Object.keys(errors).length > 0) {
            // Handle errors (e.g., display them in the UI)
            console.log("Validation errors:", errors);
            return;
        }
        // console.log('Form Data:', partnerFormData);
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);

        x.partners = partnerFormData;
        AddRegisterCustomer(x)
            .then((data) => {
                localStorage.removeItem('FormData'); // Removes the item with the key 'FormData'
                // localStorage.removeItem('UserData'); // Removes the item with the key 'UserData'
                Swal.fire({
                    title: "Thank You!",
                    text: "Your inquiry has been completed successfully. We’ll get back to you soon!",
                    icon: "success"
                });
                navigate('/main')
                // console.log(data);
                // localStorage.setItem('FormData', JSON.stringify(data))

                // if (data.partner != '0') {
                //     navigate('/partner')
                // }
                // else {
                //     alert('no partner selected')
                // }

            })
            .catch((error) => console.log(error));
    };

    // const handlePartnerPanCopyInputDocChange =async (index, e)=>{
    //     if(!(e.target.files[0])){
    //         return
    //     }

    //     // *** UPLOAD TO AZURE STORAGE ***
    //     const blobUrl = await uploadFileToBlob(e.target.files[0]).then((res) => {
    //         handlePartnerInputChange(index,`${handlePriview(e.target.files[0].name)}`)

    //     });
    // }

    // Validation of Form
    const validateDirectorFormData = (data) => {
        const errors = {};

        data.forEach((partner, index) => {
            const partnerErrors = {};

            // Ensure partner object exists before accessing its properties
            if (partner) {
                if (!partner.name) {
                    partnerErrors.name = "Name is required";
                }

                if (!partner.fatherName) {
                    partnerErrors.fatherName = "Father Name is required";
                }

                // Check if emailAddress exists and is valid
                if (!partner.emailAddress || !partner.emailAddress.includes('@')) {
                    partnerErrors.emailAddress = "Email is invalid";
                }
                if (!partner.currentAddress) {
                    partnerErrors.currentAddress = "Current Address is required";
                }

                // Check mobile number length
                if (!partner.mobileNo || partner.mobileNo.length !== 10) {
                    partnerErrors.mobileNo = "Mobile number must be 10 digits";
                }

                if (!partner.dateOfBirth) {
                    partnerErrors.dateOfBirth = "Date of Birth is required";
                } else {
                    const today = new Date();
                    const dob = new Date(partner.dateOfBirth);
                    if (dob >= today) {
                        partnerErrors.dateOfBirth = "Date of Birth must be before today";
                    }
                }

                if (!partner.Nationality) {
                    partnerErrors.Nationality = "Nationality is required";
                }

                if (!partner.placeOfBirth) {
                    partnerErrors.placeOfBirth = "Place of Birth is required";
                }

                if (!partner.Occupation) {
                    partnerErrors.Occupation = "Occupation is required";
                }

                if (!partner.educationalQualification) {
                    partnerErrors.educationalQualification = "Educational Qualification is required";
                }



                // if (!partner.currentAddress) {
                //   partnerErrors.currentAddress = "Current Address is required";
                // }

                if (!partner.currentAddressAttachment) {
                    partnerErrors.currentAddressAttachment = "Current Address Attachment is required";
                }

                if (!partner.proofOfIdentity) {
                    partnerErrors.proofOfIdentity = "Proof of Identity is required";
                }

                if (!partner.noOfSharesSubscribed) {
                    partnerErrors.noOfSharesSubscribed = "Number of Shares Subscribed is required";
                }
                if (!partner.profitLossRatio) {
                    partnerErrors.profitLossRatio = "Profit Loss Ratio is required";
                }

                if (!partner.copyOfPan) {
                    partnerErrors.copyOfPan = "Copy of PAN is required";
                }
                if (!partner.copyOfAadhar) {
                    partnerErrors.copyOfAadhar = "Copy of Aadhar is required";
                }
                if (!partner.passport) {
                    partnerErrors.passport = "Passport is required";
                }
                if (!partner.passportSizePhoto) {
                    partnerErrors.passportSizePhoto = "Passport Size Photo is required";
                }
                if (!partner.directorIdentificationNo) {
                    partnerErrors.directorIdentificationNo = "Director Identification Number is required";
                }

                // Validate entityDetails
                if (partner.entityDetails) {
                    partner.entityDetails.forEach((entity, entityIndex) => {
                        const entityErrors = {};

                        if (!entity.partnerNameOfCompany) {
                            entityErrors.partnerNameOfCompany = "Company name is required";
                        }

                        // Add other entity validations here

                        if (Object.keys(entityErrors).length > 0) {
                            partnerErrors[`entityDetail.${entityIndex}`] = entityErrors;
                        }
                    });
                }

                if (Object.keys(partnerErrors).length > 0) {
                    errors[index] = partnerErrors;
                }
            } else {
                errors[index] = { general: "Director data is missing." };
            }
        });

        return errors;
    };


    // Create an array of dropdowns with forms inside
    const partnerDropdowns = Array.from({ length: numberOfPartnerDropdowns }, (_, index) => (
        <div style={{ alignItems: "center", display: "flex", justifyContent: "center", alignContent: "center" }}>
            <Dropdown key={index} className="mb-3 mt-5"  >
                <Button
                    className="btn btn-primary"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#dropdown-basic-${index}`}
                    aria-expanded="false"
                    aria-controls={`dropdown-basic-${index}`}
                >
                    Partner Details {index + 1}
                </Button>

                <div className="collapse" id={`dropdown-basic-${index}`}>
                    <div className="card card-body" style={{ width: "700px", alignItems: "center" }}>
                        <Form style={{ width: "500px", alignContent: "center" }}>
                            <Form.Group controlId={`form${index}`}>
                                {/* <Form.Label>Form {index + 1} Input</Form.Label> */}

                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                placeholder='Enter Name'
                                                // value={partnerFormData[index].name}
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.name}
                                            />

                                            {validationErrors[index]?.name && (
                                                <div className="text-danger">{validationErrors[index].name}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Father Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="fatherName"
                                                placeholder='Enter Father Name'
                                                // value={partnerFormData[index].fatherName}
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.fatherName}
                                            />

                                            {validationErrors[index]?.fatherName && (
                                                <div className="text-danger">{validationErrors[index].fatherName}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="dateOfBirth"
                                                placeholder='Enter Date of Birth'
                                                // value={partnerFormData[index].dateOfBirth}
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.dateOfBirth}
                                            />

                                            {validationErrors[index]?.dateOfBirth && (
                                                <div className="text-danger">{validationErrors[index].dateOfBirth}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Nationality</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Nationality"
                                                placeholder='Enter Nationality'
                                                // value={partnerFormData[index].nationality}
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.Nationality}
                                            />

                                            {validationErrors[index]?.Nationality && (
                                                <div className="text-danger">{validationErrors[index].Nationality}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Place of Birth (District and State)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="placeOfBirth"
                                                placeholder='Enter Place of Birth'
                                                // value={partnerFormData[index].nationality}
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.placeOfBirth}
                                            />

                                            {validationErrors[index]?.placeOfBirth && (
                                                <div className="text-danger">{validationErrors[index].placeOfBirth}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Occupation</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Occupation"
                                                placeholder='Enter Occupation'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.Occupation}
                                            />

                                            {validationErrors[index]?.Occupation && (
                                                <div className="text-danger">{validationErrors[index].Occupation}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Educational Qualification</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="educationalQualification"
                                                placeholder='Enter Educational Qualification'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.educationalQualification}
                                            />

                                            {validationErrors[index]?.educationalQualification && (
                                                <div className="text-danger">{validationErrors[index].educationalQualification}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Copy of PAN (Self Attested)</Form.Label>
                                            <Form.Control
                                                type="file" accept="application/pdf"
                                                name="copyOfPan"
                                                //  placeholder='Enter Copy of PAN'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.copyOfPan}
                                            />

                                            {validationErrors[index]?.copyOfPan && (
                                                <div className="text-danger">{validationErrors[index].copyOfPan}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>



                                {/* <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select
                                        name="gender"
                                        // value={partnerFormData[index].gender}
                                        onChange={(event) => handlePartnerInputChange(index, event)}
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="other">Other</option>
                                    </Form.Select>
                                </Form.Group> */}

                                {/* Add other fields similarly */}

                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Copy of Aadhaar (Self Attested)</Form.Label>
                                            <Form.Control
                                                type="file" accept="application/pdf"
                                                name="copyOfAadhar"
                                                //  placeholder='Enter Copy of Aadhaar'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.copyOfAadhar}
                                            />

                                            {validationErrors[index]?.copyOfAadhar && (
                                                <div className="text-danger">{validationErrors[index].copyOfAadhar}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Passport</Form.Label>
                                            <Form.Control
                                                type="file" accept="application/pdf"
                                                name="passport"
                                                //  placeholder='Enter Copy of Aadhaar'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.passport}
                                            />

                                            {validationErrors[index]?.passport && (
                                                <div className="text-danger">{validationErrors[index].passport}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>





                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Mobile Number</Form.Label>
                                            <Form.Control
                                                type="number" min="0"
                                                name="mobileNo"
                                                placeholder='Enter Mobile Number'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.mobileNo}
                                            />

                                            {validationErrors[index]?.mobileNo && (
                                                <div className="text-danger">{validationErrors[index].mobileNo}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="emailAddress" placeholder='Enter Email'
                                                onChange={(event) => handlePartnerInputChange(index, event)}
                                                isInvalid={!!validationErrors[index]?.emailAddress}
                                            />

                                            {validationErrors[index]?.emailAddress && (
                                                <div className="text-danger">{validationErrors[index].emailAddress}</div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label>Current Address Proof (Electricity Bill/ Mobile Bill/ Tax Receipt/ Bank Statement Not Older Then 2 Months)</Form.Label>

                                    <Col md={6}>
                                        <Form.Control
                                            type="text"
                                            name="currentAddress"
                                            placeholder='Enter Current Address Proof'
                                            onChange={(event) => handlePartnerInputChange(index, event)}
                                            isInvalid={!!validationErrors[index]?.currentAddress}
                                        />

                                        {validationErrors[index]?.emailAddress && (
                                            <div className="text-danger">{validationErrors[index].emailAddress}</div>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        <Form.Control
                                            type="file" accept="application/pdf"
                                            name="currentAddressAttachment"
                                            onChange={(event) => handlePartnerInputChange(index, event)}
                                            isInvalid={!!validationErrors[index]?.currentAddressAttachment}
                                        />

                                        {validationErrors[index]?.currentAddressAttachment && (
                                            <div className="text-danger">{validationErrors[index].currentAddressAttachment}</div>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mb-3">
                                <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Profit and Loss Ratio</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="profitLossRatio"
                                        placeholder='Enter Profit and Loss Ratio'
                                        onChange={(event) => handlePartnerInputChange(index, event)}
                                        isInvalid={!!validationErrors[index]?.profitLossRatio}
                                    />

                                    {validationErrors[index]?.profitLossRatio && (
                                        <div className="text-danger">{validationErrors[index].profitLossRatio}</div>
                                    )}
                                </Form.Group>
                               
                                </Col>
                                <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Capital Contribution</Form.Label>
                                    <Form.Control
                                        type="number" min="0"
                                        placeholder='Enter Capital Contribution'
                                        name="noOfSharesSubscribed"
                                        onChange={(event) => handlePartnerInputChange(index, event)}
                                        isInvalid={!!validationErrors[index]?.noOfSharesSubscribed}
                                    />

                                    {validationErrors[index]?.noOfSharesSubscribed && (
                                        <div className="text-danger">{validationErrors[index].noOfSharesSubscribed}</div>
                                    )}
                                </Form.Group>
                                </Col>
                                </Row>
                                <Form.Group className="mb-3">
                                    <Form.Label>Proof Of Identity (Any One: Voter ID/ Passport/ Driving Licence)</Form.Label>
                                    <Form.Control
                                        type="file" accept="application/pdf"
                                        name="proofOfIdentity"
                                        onChange={(event) => handlePartnerInputChange(index, event)}
                                        isInvalid={!!validationErrors[index]?.proofOfIdentity}
                                    />

                                    {validationErrors[index]?.proofOfIdentity && (
                                        <div className="text-danger">{validationErrors[index].proofOfIdentity}</div>
                                    )}
                                </Form.Group>


                              
                              
                              
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Number of Entities in which Partners Have an Interest: Mention the number of companies they are Directors, Partners and Shareholders</Form.Label>
                                    {partnerFormData[index]?.partnerPartners?.map((entity, qIndex) => (
                                        <div key={qIndex} className="mb-3">
                                            <div className='form-group col-md-12 d-flex justify-content-between gap-3 mb-3'>

                                                <Form.Control
                                                    // type="number"
                                                    name={`partnerNameOfCompany`} // Name for entity name

                                                    // value={entity.name}
                                                    // name="educationalQualification"
                                                    onChange={(event) => handlePartnerInputChange(index, event, qIndex)}
                                                    placeholder='Name Of Company'
                                                />

                                                <Form.Control

                                                    name={`natureOfDirectorship`} // Name for qualification value

                                                    // value={entity.value}
                                                    onChange={(event) => handlePartnerInputChange(index, event, qIndex)}
                                                    placeholder='Nature Of Director'
                                                />
                                                <Form.Control
                                                    type="date"
                                                    name={`partnerSince`} // Name for qualification value

                                                    // value={entity.value}
                                                    onChange={(event) => handlePartnerInputChange(index, event, qIndex)}
                                                    placeholder='Director Since'
                                                />

                                            </div>
                                            <div className='form-group col-md-12 d-flex justify-content-between gap-3 mb-3'>

                                                <Form.Control
                                                    // type="number"
                                                    name={`shareHolderType`} // Name for entity name

                                                    // value={entity.name}
                                                    // name="educationalQualification"
                                                    onChange={(event) => handlePartnerInputChange(index, event, qIndex)}
                                                    placeholder='Shareholder Type'
                                                />
                                                <Form.Control
                                                    // type="number"
                                                    name={`noOfShares`} // Name for qualification value

                                                    // value={entity.value}
                                                    onChange={(event) => handlePartnerInputChange(index, event, qIndex)}
                                                    placeholder='No Of Shares'
                                                />
                                                <Form.Control
                                                    type="date"
                                                    name={`shareHolderSince`}

                                                    // value={entity.value}
                                                    onChange={(event) => handlePartnerInputChange(index, event, qIndex)}
                                                    placeholder='Shareholder Since'
                                                />

                                            </div>
                                            <div style={{ textAlign: 'right' }}>

                                                <Button
                                                    variant="danger"
                                                    type="button"
                                                    onClick={() => removeEducationalQualification(index, qIndex)}
                                                >
                                                    -
                                                </Button>
                                            </div>
                                        </div>))}
                                    <Button
                                        variant="success"
                                        type="button"
                                        onClick={() => addEducationalQualification(index)}
                                    >
                                        +
                                    </Button>

                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Passport Size Photo (With White Background)</Form.Label>
                                    <Form.Control
                                        type="file" accept="application/pdf"
                                        name="passportSizePhoto"
                                        onChange={(event) => handlePartnerInputChange(index, event)}
                                        isInvalid={!!validationErrors[index]?.passportSizePhoto}
                                    />

                                    {validationErrors[index]?.passportSizePhoto && (
                                        <div className="text-danger">{validationErrors[index].passportSizePhoto}</div>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Director Identification Number (DIN/DPIN)</Form.Label>
                                    <Form.Control
                                        type="number" min="0"
                                        name="directorIdentificationNo"
                                        placeholder='Enter Director Identification Number'
                                        onChange={(event) => handlePartnerInputChange(index, event)}
                                        isInvalid={!!validationErrors[index]?.directorIdentificationNo}
                                    />

                                    {validationErrors[index]?.directorIdentificationNo && (
                                        <div className="text-danger">{validationErrors[index].directorIdentificationNo}</div>
                                    )}
                                </Form.Group>


                                {/* Add other fields similarly */}

                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </Dropdown>
        </div>
    ));



    return (
        <div className="container"  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <form className="form-group col-md-12" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "200px" }} >
                {partnerDropdowns}
                <div style={{
          display: 'block',
          width: "230%",
          textAlign: 'end'
        }}>
                <Button variant="primary" className="form-group" type="submit" style={{
                    display: 'block',
                    marginLeft: 'auto'
                }} >Submit</Button>
                </div>
            </form>
        </div>
    );
};

export default DynamicForm;



