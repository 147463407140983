import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Button, Row, Col } from 'react-bootstrap'; // Make sure you have react-bootstrap installed
import { AddRegisterCustomer } from '../../helpers/PostRquest/postRquest';
import uploadFileToBlob, { handlePriview } from '../../helpers/azureBlob';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Shareholder = () => {
    const[isSubmited,setIsSubmited]=useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [validationErrors, setValidationErrors] = useState({});
    const [numberOfShareholderDropdowns, setNumberOfShareholderDropdowns] = useState('1');
    useEffect(() => {
        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);
        //  console.log(x.entityForm.noOfShareholders);

        if (x.entityForm != null) {
            setNumberOfShareholderDropdowns((x.entityForm.noOfShareholders))
        } else if (x.privateCompany != null) {
            setNumberOfShareholderDropdowns((x.privateCompany.noOfShareholders))
        } else if (x.llpCompany != null) {
            setNumberOfShareholderDropdowns((x.llpCompany.noOfShareholders))

        } else if (x.opcCompany != null) {
            setNumberOfShareholderDropdowns((x.opcCompany.noOfShareholders))

        } else if (x.otherCompany != null) {

            setNumberOfShareholderDropdowns((x.otherCompany.noOfShareholders))
        }
    }, [])
    const navigate = useNavigate();
    // Handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmited(true)
        // console.log('Form Data:', shareholderFormData);

        // setIsLoader(true)
        // console.log("Form Data:", shareholderFormData);
        const errors = validateShareholderFormData(shareholderFormData);
        setValidationErrors(errors);
        if (Object.keys(errors).length > 0) {
            // Handle errors (e.g., display them in the UI)
            console.log("Validation errors:", errors);
            return;
        }

        let x = localStorage.getItem('FormData')
        x = JSON.parse(x);

        x.shareholders = shareholderFormData;

        // console.log('Form Data:', shareholderFormData);

        // Process form data (e.g., send to an API)
        AddRegisterCustomer(x)
            .then((data) => {
                // console.log(data);
                if (data) {

                    localStorage.removeItem('FormData'); // Removes the item with the key 'FormData'
                    // localStorage.removeItem('UserData'); // Removes the item with the key 'UserData'
                    Swal.fire({
                        title: "Thank You!",
                        text: "Your inquiry has been completed successfully. We’ll get back to you soon!",
                        icon: "success"
                    });
                    navigate('/main')
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!"
                    });
                }

            })
            .catch((error) => console.log(error));
    };

    // Shareholders

    // const numberOfShareholderDropdowns = 5;

    // Initialize state for all forms
    const [shareholderFormData, setShareholderFormData] = useState(
        Array.from({ length: numberOfShareholderDropdowns }, () => ({

            name: "",
            fatherName: "",
            dateOfBirth: "",
            nationality: "",
            placeOfBirth: "",
            occupation: "",
            educationalQualification: "",
            copyOfPan: "",
            passport: "",
            mobileNo: "",
            emailAddress: "",
            boardResolution: "",
            presentAddress: "",
            presentAddressAttachment: "",
            proofOfIdentity: "",
            noOfSharesSubscribed: "",
            step: 'final'

        }))
    );

    // Handle input change
    const handleShareholderInputChange = async (index, event) => {

        const { name, value, type, files } = event.target;
        const newFormData = [...shareholderFormData];

        // Ensure that newFormData[index] is initialized
        if (index < 0 || index >= newFormData.length) {
            console.error("Index out of bounds");
            return;
        }

        if (!newFormData[index]) {
            newFormData[index] = {}; // Initialize if undefined
        }
        if (type === 'file') {

            if (!(event.target.files[0])) {
                return
            }

            // *** UPLOAD TO AZURE STORAGE ***
            const blobUrl = await uploadFileToBlob(event.target.files[0]).then((res) => {
                newFormData[index][name] = handlePriview(event.target.files[0].name);
            });
        } else {
            newFormData[index][name] = value;
        }
        if(isSubmited){
        const errors = validateShareholderFormData(shareholderFormData);
        setValidationErrors(errors);
        }
        setShareholderFormData(newFormData);
        // console.log(newFormData);

    };

    // // Handle submit
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     console.log('Form Data:', shareholderFormData);
    //     // Process form data (e.g., send to an API)
    // };

    // Create an array of dropdowns with forms inside
    const shareholdersDropdowns = Array.from({ length: numberOfShareholderDropdowns }, (_, index) => (
        <div
            style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
            }} >
            {isLoader ? (
                <div className='container'>
                    <div className=" loader"></div>
                </div>
            ) : (
                <Dropdown key={index} className="mb-3">
                    <Button
                        className="btn btn-primary"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#shareholder-dropdown-basic-${index}`}
                        aria-expanded="false"
                        aria-controls={`shareholder-dropdown-basic-${index}`}
                    >
                        Shareholder Details {index + 1}
                    </Button>

                    <div className="collapse" id={`shareholder-dropdown-basic-${index}`}>
                        <div className="card card-body" style={{ width: "750px", alignItems: "center" }}>
                            <Form style={{ width: "550px", alignContent: "center" }}>
                                <Form.Group controlId={`form${index}`}>
                                    {/* <Form.Label>Form {index + 1} Input</Form.Label> */}

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group >
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    // value={shareholderFormData[index].name}
                                                    placeholder='Enter Name'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.name}
                                                />

                                                {validationErrors[index]?.name && (
                                                    <div className="text-danger">{validationErrors[index].name}</div>
                                                )}
                                            </Form.Group>

                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Father Name  (NA for corporate)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="fatherName"
                                                    placeholder='Enter Father Name'
                                                    // value={shareholderFormData[index].fatherName}
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.fatherName}
                                                />

                                                {validationErrors[index]?.fatherName && (
                                                    <div className="text-danger">{validationErrors[index].fatherName}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Date of Birth/Date of Incorporation</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder='Date of Birth/Date of Incorporation'
                                                    name="dateOfBirth"
                                                    // value={shareholderFormData[index].fatherName}
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.dateOfBirth}
                                                />

                                                {validationErrors[index]?.dateOfBirth && (
                                                    <div className="text-danger">{validationErrors[index].dateOfBirth}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Nationality</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="nationality"
                                                    placeholder='Enter Nationality'
                                                    // value={shareholderFormData[index].fatherName}
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.nationality}
                                                />

                                                {validationErrors[index]?.nationality && (
                                                    <div className="text-danger">{validationErrors[index].nationality}</div>
                                                )}
                                            </Form.Group>

                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Place of Birth (District and State)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="placeOfBirth" placeholder='Enter Place of Birth'
                                                    // value={shareholderFormData[index].dateOfBirth}
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.placeOfBirth}
                                                />

                                                {validationErrors[index]?.placeOfBirth && (
                                                    <div className="text-danger">{validationErrors[index].placeOfBirth}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Occupation</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="occupation" placeholder='Enter Occupation'
                                                    // value={shareholderFormData[index].occupation}
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.occupation}
                                                />

                                                {validationErrors[index]?.occupation && (
                                                    <div className="text-danger">{validationErrors[index].occupation}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Education Qualification</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="educationalQualification"
                                                    placeholder='Enter Education Qualification'
                                                    // value={shareholderFormData[index].educationalQualification}
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.educationalQualification}
                                                />

                                                {validationErrors[index]?.educationalQualification && (
                                                    <div className="text-danger">{validationErrors[index].educationalQualification}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Copy of PAN</Form.Label>
                                                <Form.Control
                                                    type="file" accept="application/pdf"
                                                    name="copyOfPan"
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.copyOfPan}
                                                />

                                                {validationErrors[index]?.copyOfPan && (
                                                    <div className="text-danger">{validationErrors[index].copyOfPan}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>



                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Passport</Form.Label>
                                                <Form.Control
                                                    type="file" accept="application/pdf"
                                                    name="passport"
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.passport}
                                                />

                                                {validationErrors[index]?.passport && (
                                                    <div className="text-danger">{validationErrors[index].passport}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Mobile Number</Form.Label>
                                                <Form.Control
                                                    type="number" min="0"
                                                    name="mobileNo" placeholder='Enter Mobile Number'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.mobileNo}
                                                />

                                                {validationErrors[index]?.mobileNo && (
                                                    <div className="text-danger">{validationErrors[index].mobileNo}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    name="emailAddress"
                                                    placeholder='Enter Email'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.emailAddress}
                                                />

                                                {validationErrors[index]?.emailAddress && (
                                                    <div className="text-danger">{validationErrors[index].emailAddress}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Board Resolution (For Corporate)</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="boardResolution"
                                                    placeholder='Enter Board Resolution'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.boardResolution}
                                                />

                                                {validationErrors[index]?.boardResolution && (
                                                    <div className="text-danger">{validationErrors[index].boardResolution}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Label>Address (eg: Electricity Bill/Mobile Bill/Bank Statement)</Form.Label>
                                        <Col md={6}>
                                            <Form.Group >
                                                <Form.Control
                                                    type="text"
                                                    name="presentAddress"
                                                    placeholder='Enter Address'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.presentAddress}
                                                />

                                                {validationErrors[index]?.presentAddress && (
                                                    <div className="text-danger">{validationErrors[index].presentAddress}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="file" accept="application/pdf"
                                                    name="presentAddressAttachment"

                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.presentAddressAttachment}
                                                />

                                                {validationErrors[index]?.presentAddressAttachment && (
                                                    <div className="text-danger">{validationErrors[index].presentAddressAttachment}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Label>Proof of Identity (Any One: Voter ID/Passport/Driving Licence)</Form.Label>
                                        <Col md={6}>
                                            <Form.Group >
                                                <Form.Control
                                                    type="text"
                                                    name="proofOfIdentity"
                                                    placeholder='Enter Proof of Identity'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.proofOfIdentity}
                                                />

                                                {validationErrors[index]?.proofOfIdentity && (
                                                    <div className="text-danger">{validationErrors[index].proofOfIdentity}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Control
                                                    type="file" accept="application/pdf"
                                                    name="proofOfIdentityAttachment"

                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.proofOfIdentityAttachment}
                                                />

                                                {validationErrors[index]?.proofOfIdentityAttachment && (
                                                    <div className="text-danger">{validationErrors[index].proofOfIdentityAttachment}</div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>


                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>No. Of Shares Subscribed</Form.Label>
                                                <Form.Control
                                                    type="number" min="0"
                                                    name="noOfSharesSubscribed"
                                                    placeholder='Enter Number Of Shares Subscribed'
                                                    onChange={(event) => handleShareholderInputChange(index, event)}
                                                    isInvalid={!!validationErrors[index]?.noOfSharesSubscribed}
                                                />

                                                {validationErrors[index]?.noOfSharesSubscribed && (
                                                    <div className="text-danger">{validationErrors[index].noOfSharesSubscribed}</div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                    </Row>








                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </Dropdown>
            )}
        </div >
    ));


    // Validation of Form
    const validateShareholderFormData = (data) => {
        const errors = {};

        data.forEach((shareholder, index) => {
            const shareholderErrors = {};

            // Ensure shareholder object exists before accessing its properties
            if (shareholder) {
                if (!shareholder.name) {
                    shareholderErrors.name = "Name is required";
                }

                if (!shareholder.fatherName) {
                    shareholderErrors.fatherName = "Father Name is required";
                }

                // Check if emailAddress exists and is valid
                if (!shareholder.emailAddress || !shareholder.emailAddress.includes('@')) {
                    shareholderErrors.emailAddress = "Email is invalid";
                }
                if (!shareholder.boardResolution) {
                    shareholderErrors.boardResolution = "Board Resolution is required";
                }

                // Check mobile number length
                if (!shareholder.mobileNo || shareholder.mobileNo.length !== 10) {
                    shareholderErrors.mobileNo = "Mobile number must be 10 digits";
                }

                if (!shareholder.dateOfBirth) {
                    shareholderErrors.dateOfBirth = "Date of Birth is required";
                } else {
                    const today = new Date();
                    const dob = new Date(shareholder.dateOfBirth);
                    if (dob >= today) {
                        shareholderErrors.dateOfBirth = "Date of Birth must be before today";
                    }
                }

                if (!shareholder.nationality) {
                    shareholderErrors.nationality = "Nationality is required";
                }

                if (!shareholder.placeOfBirth) {
                    shareholderErrors.placeOfBirth = "Place of Birth is required";
                }

                if (!shareholder.occupation) {
                    shareholderErrors.occupation = "Occupation is required";
                }

                if (!shareholder.educationalQualification) {
                    shareholderErrors.educationalQualification = "Educational Qualification is required";
                }



                if (!shareholder.presentAddress) {
                    shareholderErrors.presentAddress = "Address is required";
                }

                if (!shareholder.presentAddressAttachment) {
                    shareholderErrors.presentAddressAttachment = "Address Attachment is required";
                }

                if (!shareholder.proofOfIdentity) {
                    shareholderErrors.proofOfIdentity = "Proof of Identity is required";
                }
                if (!shareholder.proofOfIdentityAttachment) {
                    shareholderErrors.proofOfIdentityAttachment = "Proof of Identity Attachment is required";
                }

                if (!shareholder.noOfSharesSubscribed) {
                    shareholderErrors.noOfSharesSubscribed = "Number of Shares Subscribed is required";
                }

                if (!shareholder.copyOfPan) {
                    shareholderErrors.copyOfPan = "Copy of PAN is required";
                }
                // if (!shareholder.copyOfAadhar) {
                //     shareholderErrors.copyOfAadhar = "Copy of Aadhar is required";
                // }
                if (!shareholder.passport) {
                    shareholderErrors.passport = "Passport is required";
                }
                // if (!shareholder.passportSizePhoto) {
                //     shareholderErrors.passportSizePhoto = "Passport Size Photo is required";
                // }
                // if (!shareholder.shareholderIdentificationNo) {
                //     shareholderErrors.shareholderIdentificationNo = "Director Identification Number is required";
                // }

                // Validate entityDetails
                if (shareholder.entityDetails) {
                    shareholder.entityDetails.forEach((entity, entityIndex) => {
                        const entityErrors = {};

                        if (!entity.shareholderNameOfCompany) {
                            entityErrors.shareholderNameOfCompany = "Company name is required";
                        }

                        // Add other entity validations here

                        if (Object.keys(entityErrors).length > 0) {
                            shareholderErrors[`entityDetail.${entityIndex}`] = entityErrors;
                        }
                    });
                }

                if (Object.keys(shareholderErrors).length > 0) {
                    errors[index] = shareholderErrors;
                }
            } else {
                errors[index] = { general: "Director data is missing." };
            }
        });

        return errors;
    };

    return (
        <div className="container" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <form onSubmit={handleSubmit}  >

                {shareholdersDropdowns}
                <Button variant="primary" type="submit" style={{
                    display: 'block',
                    marginLeft: 'auto'
                }}>Submit</Button>
            </form>
        </div>
    );
};

export default Shareholder;




